import { UserContext } from "@components/user-context/user-context";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@infinitaslearning/pixel-design-system";
import XmarkIcon from "@infinitaslearning/pixel-icons/Xmark";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { getFullUserName } from "@utils/common";
import { usePublicationEnv } from "@utils/persisted-values/use-publication-env";
import { type OpCo, useBrand } from "@utils/use-brand";
import { useContext, useMemo } from "react";

import { AccountLinks } from "./components/account-links";
import { FeatureFlagSelector } from "./components/feature-flag-selector";
import { useTranslation } from "next-i18next";
import { DataMaskWrapperSpan } from "@components/data-mask-wrapper";
import { getToggleGroupButtonSX } from "@utils/styling-helpers";
import { InsiderProgramCTA } from "./components/insider-program-cta";
import { PepTypography } from "@components/pep-typography";
import { MethodLicenseFilter } from "@components/user-sidebar/components/method-license-filter";

type UserSidebarProps = {
  closeSidebar: () => void;
  isOpen: boolean;
};

const getPrivacyLink = (opCo: OpCo) =>
  ({
    liber: "https://www.liber.se/om-liber/integritetspolicy",
    plantyn: "https://www.plantyn.com/privacy",
    noordhoff: "https://www.noordhoff.nl/privacy",
  })[opCo];

const trackElementClick = (elementId: string, value: string) => {
  getAnalyticsTracker()?.elementClicked({
    elementId,
    type: "button",
    module: "user-sidebar",
    itemId: elementId,
    value,
  });
};

const UserSidebar: React.FC<UserSidebarProps> = ({ isOpen, closeSidebar }) => {
  const userContext = useContext(UserContext);
  const [userEnv, setUserEnv] = usePublicationEnv();
  const isStaging = userEnv === "staging";
  const { opCo } = useBrand();
  const { t: tSidebar } = useTranslation("common", { keyPrefix: "user-sidebar" });
  const { t: tCommon } = useTranslation("common");

  const theme = useTheme();
  const privacyLink = useMemo(() => getPrivacyLink(opCo), [opCo]);

  const handleClose = (ctx: "click-away" | "close-button") => {
    closeSidebar();
    trackElementClick("close-sidebar", ctx);
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={() => handleClose("click-away")}>
      <IconButton
        aria-label={tSidebar("close")}
        onClick={() => handleClose("close-button")}
        sx={{ alignSelf: "flex-end" }}
      >
        <XmarkIcon fontSize="xLarge" />
      </IconButton>

      <Box
        data-usetiful="sidepanel"
        role="presentation"
        sx={(theme) => ({
          boxSizing: "content-box",
          width: 350,
          padding: theme.spacing(0, 2),
          display: "flex",
          flex: 1,
          flexDirection: "column",
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PepTypography variant="body1Medium">
              <DataMaskWrapperSpan>{getFullUserName(userContext?.me.data)}</DataMaskWrapperSpan>
            </PepTypography>
            <PepTypography variant="body1Light" sx={{ color: "neutrals.50", fontSize: 12 }}>
              ({tCommon("id")}
              <DataMaskWrapperSpan>{userContext?.me.data?.id}</DataMaskWrapperSpan>)
            </PepTypography>
          </Box>
          <AccountLinks />
        </Box>

        <Divider sx={{ my: 2 }} />
        <InsiderProgramCTA />

        {userContext?.roles.isPublisher && (
          <ToggleButtonGroup
            color="primary"
            value={isStaging ? "stag" : "prod"}
            exclusive
            onChange={(_, newValue: string) => {
              const trackValue = newValue ? newValue : userEnv ?? "production";
              trackElementClick("env-toggle", trackValue);
              setUserEnv(newValue === "stag" ? "staging" : "production");
            }}
            sx={{
              justifyContent: "center",
              gap: 0,
              // set border for children broken by pixel version 29.4.3
              button: {
                border: `1px solid ${theme.pixel.color.palette.neutrals[80]} !important`,
              },
            }}
          >
            <ToggleButton value="prod" sx={getToggleGroupButtonSX("left", true)}>
              {tSidebar("production_endpoint_label")}
            </ToggleButton>
            <ToggleButton value="stag" sx={getToggleGroupButtonSX("right", true)}>
              {tSidebar("staging_endpoint_label")}
            </ToggleButton>
          </ToggleButtonGroup>
        )}

        {userContext?.roles.isPublisher && <MethodLicenseFilter />}

        <FeatureFlagSelector />

        <Stack sx={{ width: "100%", textAlign: "center", gap: 1 }}>
          <Link href={privacyLink} target="_blank">
            {tSidebar("privacy_link_label")}
          </Link>
          <PepTypography
            variant="body1Bold"
            px={2}
            py={0.5}
            t={tSidebar}
            translationKey="copyright"
            tOptions={{ currentYear: new Date().getFullYear() }}
          />
        </Stack>
      </Box>
    </Drawer>
  );
};

export default UserSidebar;
