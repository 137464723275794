import { initPlatformPreferencesHook } from "@infinitaslearning/preferences/react";
import { isLocalEnv } from "@utils/config";
import { useConfig } from "src/config/hook";
import { z, type ZodObject, type ZodRawShape } from "zod";

type SchemaType<T extends ZodObject<ZodRawShape>> = z.infer<T>;

const storedMethodWeeksSchema = z.object({
  methodName: z.string(),
  subjectName: z.string(),
  chapterName: z.string().optional(),
  paragraphName: z.string().optional(),
  methodgroupName: z.string().optional(),
  urlComponents: z.object({
    subjectName: z.string(),
    methodId: z.string(),
    methodgroupId: z.string().optional(),
    chapterId: z.string().optional(),
    paragraphId: z.string().optional(),
    learningUnitId: z.string().optional(),
  }),
  learningUnitDetails: z
    .object({
      hasDigitalContent: z.boolean(),
      hasBoardBookContent: z.boolean(),
    })
    .optional(),
  classroomId: z.string(),
});

export type StoredMethodWeeks = z.infer<typeof storedMethodWeeksSchema>;

const preferencesSchema = z.object({
  storedMethodWeeks: z.array(storedMethodWeeksSchema),
  lastSeenUsetifulTour: z.string(),
  tallyShowAfterDate: z.string(),
  submissionsShownDate: z.record(z.string(), z.string()),
  tallyShownInfo: z.object({
    shownOn: z.string(),
    formId: z.string(),
  }),
  storedInClassLayoutSettings: z.object({
    isContentNavRight: z.boolean(),
  }),
  lastDashboardTimeFilter: z.union([
    z.literal("all"),
    z.literal("ongoing"),
    z.literal("finished"),
    z.literal("future"),
  ]),
  lessonDashboardVisitCount: z.number(),
  filteredMethodLicenses: z.array(z.string()),
});

export const usePlatformPreferences = <K extends keyof SchemaType<typeof preferencesSchema>>(
  key: K,
  defaultValue?: SchemaType<typeof preferencesSchema>[K],
  skipFetch?: boolean,
) => {
  const config = useConfig();

  return initPlatformPreferencesHook({
    schema: preferencesSchema,
    platform: "pep",
    ...(isLocalEnv(config) && {
      endpointBase: config.preferencesApiUrl,
    }),
  })(key, defaultValue, skipFetch);
};
