import { useQuery } from "@tanstack/react-query";
import { useBrand } from "@utils/use-brand";
import { useSession } from "next-auth/react";

import FileLinesIcon from "@infinitaslearning/pixel-icons/FileLines";
import MessageIcon from "@infinitaslearning/pixel-icons/Message";
import CalendarIcon from "@infinitaslearning/pixel-icons/Calendar";
import FolderIcon from "@infinitaslearning/pixel-icons/Folder";
import PenNibIcon from "@infinitaslearning/pixel-icons/PenNib";
import UserMagnifyingGlassIcon from "@infinitaslearning/pixel-icons/UserMagnifyingGlass";
import UsersIcon from "@infinitaslearning/pixel-icons/Users";
import GearIcon from "@infinitaslearning/pixel-icons/Gear";
import { useRouter } from "next/router";
import { useMeQuery } from "@generated/graphql";

type ScoodleFeatures = {
  agenda: boolean;
  authorTool: boolean;
  care: boolean;
  classes: boolean;
  communication: boolean;
  reports: boolean;
  schoolAdmin: boolean;
  userSearch: boolean;
};

// this is needed to fetch the scoodle items (features) from the scoodle primary api
const ENDPOINT = "https://scoodleprimaryapi.scoodle.be/graphql";

const scoodlePrimaryNavigation = {
  agenda: {
    href: "https://pe.scoodle.be/land-from-external?feature=agenda",
    label: "Agenda",
    icon: <CalendarIcon fontSize="large" color="primary" />,
  },
  care: {
    href: "https://pe.scoodle.be/land-from-external?feature=care",
    label: "Care",
    icon: <FolderIcon fontSize="large" color="primary" />,
  },
  reports: {
    href: "https://pe.scoodle.be/land-from-external?feature=reports",
    label: "Reports",
    icon: <FileLinesIcon fontSize="large" color="primary" />,
  },
  communication: {
    href: "https://pe.scoodle.be/land-from-external?feature=communication",
    label: "Communication",
    icon: <MessageIcon fontSize="large" color="primary" />,
  },
  schoolAdmin: {
    href: "https://pe.scoodle.be/land-from-external?feature=schoolAdmin",
    label: "Admin",
    icon: <GearIcon fontSize="large" color="primary" />,
  },
  classes: {
    href: "https://pe.scoodle.be/land-from-external?feature=classes",
    label: "My classes",
    icon: <UsersIcon fontSize="large" color="primary" />,
  },
};

const scoodleBottomNavigation = {
  authorTool: {
    href: "https://pe.scoodle.be/land-from-external?feature=authorTool",
    label: "Author tool",
    icon: <PenNibIcon fontSize="large" color="primary" />,
  },
  userSearch: {
    href: "https://pe.scoodle.be/land-from-external?feature=userSearch",
    label: "Search users",
    icon: <UserMagnifyingGlassIcon fontSize="large" color="primary" />,
  },
};

export const useGetScoodleItems = () => {
  const { data } = useSession();
  const { opCo } = useBrand();
  const router = useRouter();

  const isPlantyn = opCo === "plantyn";
  const idToken = data?.idToken;

  const { data: meData } = useMeQuery(undefined, {
    enabled: !!idToken && isPlantyn,
  });

  return useQuery({
    queryKey: ["scoodle-features"],
    queryFn: async () => {
      const response = await fetch(ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          query: `{
            currentUser {
              availableFeatures {
                agenda
                authorTool
                care
                classes
                communication
                reports
                schoolAdmin
                userSearch
              }
            }
          }`,
          variables: null,
        }),
      });

      if (!response.ok) {
        throw new Error("network error");
      }

      const availableFeatures = (await response.json()).data?.currentUser
        ?.availableFeatures as ScoodleFeatures;

      const classroomId = router.query.classroomId as string;
      const organizationId = meData?.me?.organizationId;

      const extraQSParams = `&classId=${classroomId}&schoolId=${organizationId}`;

      return {
        primaryNavigation: Object.entries(scoodlePrimaryNavigation)
          .filter(([key]) => availableFeatures[key as keyof ScoodleFeatures])
          .map(([_, value]) => ({
            ...value,
            href: `${value.href}${extraQSParams}`,
          })),
        bottomNavigation: Object.entries(scoodleBottomNavigation)
          .filter(([key]) => availableFeatures[key as keyof ScoodleFeatures])
          .map(([_, value]) => ({
            ...value,
            href: `${value.href}${extraQSParams}`,
          })),
      };
    },
    enabled: !!idToken && isPlantyn,
  });
};
