import { Box, List, ListItemButton, ListItemText } from "@infinitaslearning/pixel-design-system";
import {
  SECONDARY_NAV_WIDTH_OPEN,
  useLeftNavStore,
  PRIMARY_NAV_WIDTH_HOVERED,
  PRIMARY_NAV_WIDTH,
} from "@lib/zustand/left-nav-store";
import CloseIcon from "@infinitaslearning/pixel-icons/ArrowLeftToLine";
import OpenIcon from "@infinitaslearning/pixel-icons/Bars";
import Link from "next/link";
import type { LefthandNavigationItem } from "@components/lefthand-navigation/lefthand-navigation";

const TRANSLATE_WHEN_NOT_HOVERED = 0;
const TRANSLATE_WHEN_CLOSED = -(SECONDARY_NAV_WIDTH_OPEN - PRIMARY_NAV_WIDTH) - 6; // this magic number 6 is because of the padding

const LefthandSecondaryNavigation = ({
  currentPathWithoutQuery,
  items,
}: {
  currentPathWithoutQuery: string;
  items: LefthandNavigationItem[];
}) => {
  const { isLeftNavHovered, isSecondaryNavOpen, toggleIsSecondaryNavOpen, isDisabledBodyPadding } =
    useLeftNavStore();

  const translateLeft =
    isLeftNavHovered && !isDisabledBodyPadding
      ? PRIMARY_NAV_WIDTH_HOVERED - PRIMARY_NAV_WIDTH
      : TRANSLATE_WHEN_NOT_HOVERED;
  console.log("translateLeft", translateLeft);
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: PRIMARY_NAV_WIDTH,
        bottom: 0,
        transform: isSecondaryNavOpen
          ? `translate3d(${translateLeft}px, 0, 0)`
          : `translate3d(${TRANSLATE_WHEN_CLOSED + translateLeft}px, 0, 0)`,
        transition: "transform .2s ease-in-out",
        backgroundColor: (t) => t.palette.background.paper,
        p: 1,
        borderRight: (t) => `1px solid ${t.palette.divider}`,
        zIndex: (theme) => theme.zIndex.appBar - 1,
        width: SECONDARY_NAV_WIDTH_OPEN,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        onClick={toggleIsSecondaryNavOpen}
        sx={{ cursor: "pointer", p: 1 }}
      >
        {isSecondaryNavOpen ? (
          <CloseIcon color="primary" fontSize="large" />
        ) : (
          <OpenIcon color="primary" fontSize="large" />
        )}
      </Box>
      <Box
        sx={{
          color: (theme) => theme.pixel.color.palette.neutrals[20],
        }}
      >
        <List
          sx={{
            opacity: isSecondaryNavOpen ? 1 : 0,
            pointerEvents: isSecondaryNavOpen ? "auto" : "none",
            transition: "opacity 0.2s ease-in-out, transform 0.2s ease-in-out",
            transitionDelay: isSecondaryNavOpen ? "0.2s" : "0s",
            transform: isSecondaryNavOpen ? "translateX(0)" : "translateX(-20px)",
            p: 0,
          }}
        >
          {items.map((item) => (
            <ListItemButton
              key={item.href}
              sx={{
                backgroundColor: item.isActive?.(currentPathWithoutQuery)
                  ? (t) => t.pixel.color.primary.container
                  : "transparent",
                borderRadius: 2,
                color: "primary.main",
                "&:hover": {
                  textDecoration: "none",
                  backgroundColor: (t) => t.pixel.color.inverseOnSurface,
                },
                mb: 1,
                p: 1,
                pl: 2,
              }}
              href={item.href}
              LinkComponent={Link}
            >
              <ListItemText primary={item.label} disableTypography sx={{ fontSize: 12 }} />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default LefthandSecondaryNavigation;
