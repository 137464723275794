import type { GetSubjectsAndMethodsQuery } from "@generated/graphql";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { slugify } from "@utils/slugify";
import { reject } from "lodash";

export type UrlComponents = {
  subjectName: string;
  methodId: string;
  methodgroupId?: string;
  chapterId?: string;
  paragraphId?: string;
  learningUnitId?: string;
};

export type RecentMethodWeekData = {
  methodName: string;
  subjectName: string;
  chapterName?: string;
  paragraphName?: string;
  methodgroupName?: string;
  urlComponents: UrlComponents;
  classroomId: string;
  learningUnitDetails?: {
    hasDigitalContent: boolean;
    hasBoardBookContent: boolean;
  };
};

export type LastOpenedMethodWeekStore = Array<RecentMethodWeekData>;

const BREAKPOINT_MAX_ITEM_VALUES = [
  { minHeight: 1100, maxItems: 10 },
  { minHeight: 900, maxItems: 8 },
  { minHeight: 750, maxItems: 6 },
  { minHeight: 550, maxItems: 4 },
  { minHeight: 450, maxItems: 2 },
];

const DEFAULT_ROW_COUNT = 2;
const ROW_BREAKPOINTS = [
  { minWidth: 800, columns: 3 },
  { minWidth: 0, columns: DEFAULT_ROW_COUNT },
];

const DEFAULT_COLUMN_COUNT = 1;
const BREAKPOINT_FOR_COLUMNS = [
  { minHeight: 1200, rows: 2 },
  { minHeight: 0, rows: DEFAULT_COLUMN_COUNT },
];

export const getFinalDataArray = (
  data: RecentMethodWeekData[],
  winHeight: number,
  winWidth: number,
) => {
  // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
  const finalDataArray: any = [];
  const isSmallWidth = winWidth < 800;
  const rowCount =
    BREAKPOINT_FOR_COLUMNS.find((x) => x.minHeight <= winHeight)?.rows ?? DEFAULT_ROW_COUNT;
  const columnCount = isSmallWidth
    ? 1
    : ROW_BREAKPOINTS.find((x) => x.minWidth <= winWidth)?.columns ?? DEFAULT_COLUMN_COUNT;
  const maxItemCount = rowCount * columnCount;
  for (let i = 0; i < data.length; i += maxItemCount) {
    finalDataArray.push(data.slice(i, i + maxItemCount));
  }
  return finalDataArray;
};

export const getSlicedMethodData = (data: RecentMethodWeekData[], minHeight: number) => {
  // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
  const finalDataArray: any = [];
  const maxItemCount =
    BREAKPOINT_MAX_ITEM_VALUES.find((x) => x.minHeight <= minHeight)?.maxItems ?? 2;
  for (let i = 0; i < data.length; i += maxItemCount) {
    finalDataArray.push(data.slice(i, i + maxItemCount));
  }
  return finalDataArray;
};

export const formatSubjectAndMethodsDataToMethodData = (
  subjectsAndMethods: GetSubjectsAndMethodsQuery,
  classroomId: string,
): RecentMethodWeekData[] => {
  // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
  const data: any = [];
  subjectsAndMethods.subjects.map((subject) => {
    subject.methodsLicensed.flatMap((method) => {
      data.push({
        methodName: method.name,
        subjectName: subject.name,
        classroomId,
        urlComponents: {
          subjectName: subject.name,
          methodId: method.id,
        },
      });
    });
  });
  return data;
};

export const createContinueItemUrl = (urlComponents: UrlComponents) => {
  const { subjectName, methodId, methodgroupId, chapterId, paragraphId, learningUnitId } =
    urlComponents;
  const slugifiedSubjectName = slugify(subjectName).toLocaleLowerCase();

  if (methodgroupId && chapterId && paragraphId) {
    if (learningUnitId) {
      return `/content/${slugifiedSubjectName}/${methodId}/${methodgroupId}/${chapterId}/${paragraphId}/${learningUnitId}`;
    }
    return `/content/${slugifiedSubjectName}/${methodId}/${methodgroupId}/${chapterId}/${paragraphId}`;
  }
  return `/content/${slugifiedSubjectName}/${methodId}`;
};

export const createContinueInclassUrl = (urlComponents: UrlComponents) => {
  const { subjectName, methodId, methodgroupId, chapterId, paragraphId, learningUnitId } =
    urlComponents;
  const slugifiedSubjectName = slugify(subjectName).toLocaleLowerCase();

  if (!methodgroupId || !chapterId || !paragraphId || !learningUnitId) {
    return createContinueItemUrl(urlComponents);
  }

  return `/content/${slugifiedSubjectName}/${methodId}/${methodgroupId}/${chapterId}/${paragraphId}/${learningUnitId}/inclass`;
};

export const hasSameClassroom = (
  storedData: RecentMethodWeekData,
  newData: RecentMethodWeekData,
) => {
  return storedData.classroomId === newData.classroomId;
};

export const getNewStoredMethodWeeks = (
  weekData: RecentMethodWeekData,
  storedMethodWeeks: LastOpenedMethodWeekStore,
  isStaging: boolean,
) => {
  const fallbackReturnValue = [weekData];

  if (!storedMethodWeeks) return fallbackReturnValue;
  if (!storedMethodWeeks.length) return fallbackReturnValue;

  const hasSameUrlAndClassroom = storedMethodWeeks.some((week) => {
    const hasSameUrl =
      createContinueItemUrl(week.urlComponents) === createContinueItemUrl(weekData.urlComponents);
    return hasSameUrl && hasSameClassroom(week, weekData);
  });

  const hasSameMethodAndClassroom = storedMethodWeeks.some((week) => {
    const hasSameMethod = week.methodName === weekData.methodName;
    return hasSameMethod && hasSameClassroom(week, weekData);
  });

  if (hasSameUrlAndClassroom || isStaging) {
    return storedMethodWeeks;
  }

  if (hasSameMethodAndClassroom) {
    // only for the current classroom
    const storedMinusMethod = reject(
      storedMethodWeeks,
      (week) =>
        week.methodName === weekData.methodName && week.classroomId === weekData.classroomId,
    );
    return [weekData, ...storedMinusMethod];
  }

  return [weekData, ...storedMethodWeeks];
};

export const trackHomeElementClicked = (elementId: string, value?: string, type = "button") => {
  getAnalyticsTracker()?.elementClicked({
    elementId,
    type,
    module: "home",
    itemId: elementId,
    value,
  });
};
