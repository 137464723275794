import { Box, Stack } from "@infinitaslearning/pixel-design-system";

import Image from "next/image";

// returns a different image based on the feature flag id
// at the moment we have no custom images to use
// the switch operator is being left here as reference
const FeatureFlagImage: React.FC<{ id: string; altTitle: string }> = ({ id, altTitle }) => {
  switch (id) {
    // example:
    // case "useLessonFinder": {
    //   return <Image width={120} height={120} src={someImage} alt="background" />;
    // }
    case "displayTextInteractionButtonPDF": {
      return (
        <Image
          width={160}
          height={160}
          style={{ borderRadius: 30 }}
          src={"/images/features/tts.webp"}
          alt={altTitle}
        />
      );
    }
    case "viewPlayerInteractionsPage": {
      return (
        <Image
          width={160}
          height={160}
          style={{ borderRadius: 30 }}
          src={"/images/features/exportanswers.webp"}
          alt={altTitle}
        />
      );
    }
    case "enableCompass": {
      return (
        <Image
          width={160}
          height={160}
          style={{ borderRadius: 30 }}
          src={"/images/features/compass.webp"}
          alt={altTitle}
        />
      );
    }
    case "enableRuler": {
      return (
        <Image
          width={160}
          height={160}
          style={{ borderRadius: 30 }}
          src={"/images/features/ruler.webp"}
          alt={altTitle}
        />
      );
    }
    case "enableProtractor": {
      return (
        <Image
          width={160}
          height={160}
          style={{ borderRadius: 30 }}
          src={"/images/features/protractor.webp"}
          alt={altTitle}
        />
      );
    }
    case "umcLessonDashboard": {
      return (
        <Image
          width={160}
          height={160}
          style={{ borderRadius: 30 }}
          src="/images/features/new-lesson-dashboard.webp"
          alt={altTitle}
        />
      );
    }

    default: {
      return (
        <Stack gap={2} key={id}>
          <Box width={100} height={20} bgcolor="primary.dark" borderRadius={30} />
          <Box width={100} height={20} bgcolor="primary.dark" borderRadius={30} ml={4} />
          <Box width={100} height={20} bgcolor="primary.dark" borderRadius={30} ml={2} />
        </Stack>
      );
    }
  }
};

export default FeatureFlagImage;
