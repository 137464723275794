import { Box, Button, IconButton, List, TextField } from "@infinitaslearning/pixel-design-system";
import {
  FEATURE_TOGGLES_COOKIE,
  FEATURE_TOGGLES_COOKIE_MAX_AGE,
  UserContext,
} from "@components/user-context/user-context";
import { useContext, useMemo, useState } from "react";

import FeatureFlagToggle from "./feature-flag-toggle";
import { PepTypography } from "@components/pep-typography";
import SearchIcon from "@infinitaslearning/pixel-icons/MagnifyingGlass";
import XmarkIcon from "@infinitaslearning/pixel-icons/Xmark";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { getFeatureList } from "./helpers/getFeatureList";
import { useCookies } from "react-cookie";
import { useGammaWave } from "./helpers/useGammaWave.hook";
import { useTranslation } from "next-i18next";

type FeatureFlagSelectorProps = {
  wide?: boolean;
  showGammaOnly?: boolean;
};

export const FeatureFlagSelector = ({
  showGammaOnly = false,
  wide = false,
}: FeatureFlagSelectorProps) => {
  const { t } = useTranslation("common", { keyPrefix: "config-toggles" });
  const userContext = useContext(UserContext);
  const [cookies, setCookie] = useCookies([FEATURE_TOGGLES_COOKIE]);
  const [searchQuery, setSearchQuery] = useState("");

  const features = getFeatureList(userContext).filter((feature) =>
    showGammaOnly ? feature.isGammaWaveFeature : true,
  );

  const featuresWithTranslations = useMemo(() => {
    return features.map((feature) => ({
      ...feature,
      name: t(`toggles.${feature.key}.name`),
      description: t(`toggles.${feature.key}.description`),
    }));
  }, [features, t]);

  const filteredFeatures = featuresWithTranslations.filter((feature) =>
    [feature.name, feature.description]
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.trim().toLowerCase()),
  );

  const { isGammaWaveEnabled, isUserInGammaRole } = useGammaWave();

  const searchFilter = () => (
    <Box sx={{ p: 1, mt: 1 }}>
      <TextField
        fullWidth
        size="small"
        placeholder={t("search")}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: searchQuery && (
            <IconButton
              aria-label="clear search"
              onClick={() => setSearchQuery("")}
              size="small"
              edge="end"
            >
              <XmarkIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </Box>
  );

  if (features.length > 0 && filteredFeatures.length === 0) {
    return (
      <>
        {searchFilter()}
        <Box p={2}>
          <PepTypography textAlign="center" t={t} translationKey="no-features" />
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Button variant="text" onClick={() => setSearchQuery("")} size="small" sx={{ mt: 1 }}>
              {t("clear-search")}
            </Button>
          </Box>
        </Box>
      </>
    );
  }

  const toggle = (key: string) => {
    const selectedFeature = features.find((x) => x.key === key);
    const newValue = !selectedFeature?.value;
    const featureFlagCookie = cookies[FEATURE_TOGGLES_COOKIE] ?? [];

    const updatedFeatureFlagCookie = featureFlagCookie.filter(
      (keyValue: { key: string; value: string }) =>
        keyValue.key !== key && features.find((x) => x.key === keyValue.key),
    );
    updatedFeatureFlagCookie.push({ key: key, value: newValue });
    setCookie(FEATURE_TOGGLES_COOKIE, updatedFeatureFlagCookie, {
      maxAge: FEATURE_TOGGLES_COOKIE_MAX_AGE,
      path: "/",
      sameSite: "strict",
    });
    if (selectedFeature?.isGammaWaveFeature) {
      getAnalyticsTracker()?.elementClicked({
        elementId: "gamma-wave-toggle",
        type: "toggle",
        value: newValue ? "on" : "off",
        itemId: key,
      });
    }

    if (userContext) {
      const newFeatures = userContext.features?.map((featureFlag) => {
        return featureFlag.key === key
          ? {
              key: key,
              value: newValue,
              isToggleable: true,
              isGammaWaveFeature: featureFlag.isGammaWaveFeature,
            }
          : featureFlag;
      });

      userContext.setFeatures(newFeatures);
    }
  };

  return (
    <>
      {features.length > 0 && searchFilter()}
      <List sx={{ mt: 2, mb: 2, overflow: "auto" }}>
        {filteredFeatures.map((feature) => (
          <FeatureFlagToggle
            key={feature.key}
            id={feature.key}
            value={feature.value}
            toggle={toggle}
            wide={wide}
            name={feature.name}
            description={feature.description}
            showFeedback={isGammaWaveEnabled && isUserInGammaRole && feature.isGammaWaveFeature}
            searchValue={searchQuery}
          />
        ))}
      </List>
    </>
  );
};
