import { createWithEqualityFn as create } from "zustand/traditional";

type LeftNavStoreType = {
  isLeftNavHovered: boolean;
  toggleIsLeftNavHovered: () => void;
  setIsLeftNavHovered: (leftNavHovered: boolean) => void;
  isSecondaryNavOpen: boolean;
  toggleIsSecondaryNavOpen: () => void;
  setIsSecondaryNavOpen: (secondaryNavOpen: boolean) => void;
  isDisabledBodyPadding: boolean;
};

export const PRIMARY_NAV_WIDTH = 64;
export const PRIMARY_NAV_WIDTH_HOVERED = 200;
export const SECONDARY_NAV_WIDTH = 64;
export const SECONDARY_NAV_WIDTH_OPEN = 200;

const createLeftNavStore = () => {
  return create<LeftNavStoreType>((set) => ({
    isLeftNavHovered: false,
    isSecondaryNavOpen: true,
    isDisabledBodyPadding: false,
    toggleIsLeftNavHovered: () =>
      set((state) => ({
        ...state,
        isLeftNavHovered: !state.isLeftNavHovered,
      })),
    setIsLeftNavHovered: (leftNavHovered: boolean) =>
      set((state) => ({
        ...state,
        isLeftNavHovered: leftNavHovered,
      })),
    toggleIsSecondaryNavOpen: () =>
      set((state) => ({
        ...state,
        isSecondaryNavOpen: !state.isSecondaryNavOpen,
      })),
    setIsSecondaryNavOpen: (secondaryNavOpen: boolean) =>
      set((state) => ({
        ...state,
        isSecondaryNavOpen: secondaryNavOpen,
      })),
  }));
};

export const getBodyPaddingLeft = (
  isLeftNavHovered: boolean,
  hasSecondaryNav: boolean,
  isSecondaryNavOpen: boolean,
) => {
  if (!hasSecondaryNav) {
    return isLeftNavHovered ? PRIMARY_NAV_WIDTH_HOVERED : PRIMARY_NAV_WIDTH;
  }
  if (isSecondaryNavOpen) {
    return isLeftNavHovered
      ? PRIMARY_NAV_WIDTH_HOVERED + SECONDARY_NAV_WIDTH_OPEN
      : PRIMARY_NAV_WIDTH + SECONDARY_NAV_WIDTH_OPEN;
  }
  return isLeftNavHovered
    ? PRIMARY_NAV_WIDTH_HOVERED + SECONDARY_NAV_WIDTH
    : PRIMARY_NAV_WIDTH + SECONDARY_NAV_WIDTH;
};

export const useLeftNavStore = createLeftNavStore();
