import { getAllValues } from "@lib/configcat";
import type { secretKey } from "@utils/config";
import type { OpCo } from "@utils/use-brand";
import type { Session } from "next-auth";
import type { SafeConfigType } from "src/config";

export const SupportedOpCos: IdpOpCoSettings[] = [
  { opCo: "noordhoff", locale: "nl" },
  { opCo: "plantyn", locale: "nl-be" },
  { opCo: "plantyn", locale: "fr-be" },
  { opCo: "liber", locale: "sv" },
];

type IdpSetting = {
  id: string;
  clientId: string;
  issuer: string;
  usePrompt: boolean;
  secretEnv: secretKey;
  jwksEndpoint: string;
  scopes: string;
};

enum ConfigcatIdpKeys {
  Id = "idpId",
  ClientId = "idpClientId",
  UsePrompt = "idpUsePrompt",
  Issuer = "idpIssuer",
  JwksEndpoint = "idpJwksEndpoint",
  Scopes = "idpScopes",
}

type IdpOpCoSettings = {
  opCo: OpCo;
  locale: string;
  keys?: IdpSetting;
};

function getSecretKey(opCo: OpCo): secretKey {
  // Todo : This will be refactored after the release pipeline is modified
  if (opCo === "noordhoff") {
    return "IDP_CLIENT_SECRET";
  }
  return `IDP_CLIENT_SECRET_${opCo.toUpperCase()}` as secretKey;
}

async function getAllOpCoIdpSettingsFromConfigCat(
  config: SafeConfigType,
): Promise<IdpOpCoSettings[]> {
  const retrievedConfigurations: IdpOpCoSettings[] = [];
  // biome-ignore lint/complexity/noForEach: foreach is better here
  SupportedOpCos.forEach((opCoConfig) => {
    retrievedConfigurations.push({ opCo: opCoConfig.opCo, locale: opCoConfig.locale });
  });

  const configCatOpCoIdpSettingsPromises = SupportedOpCos.map(async (opCoConfig) => {
    return await getAllValues(opCoConfig.opCo, config, undefined, { locale: opCoConfig.locale });
  });

  const configCatOpCoIdpSettings = await Promise.all(configCatOpCoIdpSettingsPromises);

  configCatOpCoIdpSettings.map((featureFlags, index) => {
    retrievedConfigurations[index].keys = {
      id: featureFlags.find((featureFlag) => featureFlag.key === ConfigcatIdpKeys.Id)?.value,
      clientId: featureFlags.find((featureFlag) => featureFlag.key === ConfigcatIdpKeys.ClientId)
        ?.value,
      issuer: featureFlags.find((featureFlag) => featureFlag.key === ConfigcatIdpKeys.Issuer)
        ?.value,
      usePrompt: featureFlags.find((featureFlag) => featureFlag.key === ConfigcatIdpKeys.UsePrompt)
        ?.value,
      secretEnv: getSecretKey(retrievedConfigurations[index].opCo),
      jwksEndpoint: featureFlags.find(
        (featureFlag) => featureFlag.key === ConfigcatIdpKeys.JwksEndpoint,
      )?.value,
      scopes: featureFlags.find((featureFlag) => featureFlag.key === ConfigcatIdpKeys.Scopes)
        ?.value,
    };
  });

  return retrievedConfigurations;
}

export async function getIdpConfigByAud(aud: string, config: SafeConfigType): Promise<IdpSetting> {
  const idpConfigs = await getAllOpCoIdpSettingsFromConfigCat(config);
  const clientIdConfig = idpConfigs.find((config) => config.keys?.clientId === aud)?.keys;

  if (!clientIdConfig) throw `Missing IDP Config for ${aud}`;
  return clientIdConfig;
}

export async function getIdpConfigByOpCo(
  opCo: OpCo,
  locale: string,
  config: SafeConfigType,
): Promise<IdpSetting> {
  const idpConfigs = await getAllOpCoIdpSettingsFromConfigCat(config);

  let idpConfig = idpConfigs.find((config) => config.opCo === opCo && config.locale === locale);
  if (idpConfig?.keys) {
    return idpConfig.keys;
  }

  // fallback only needed for local development (using different locales, fe )
  idpConfig = idpConfigs.find((config) => config.opCo === opCo);

  if (!idpConfig || !idpConfig?.keys) {
    throw `Missing IDP Config for ${opCo}`;
  }

  return idpConfig.keys;
}

export async function getSignOutUrl(redirectUrl: string, session: Session, config: SafeConfigType) {
  const idpConfig = await getIdpConfigByAud(session.aud, config);

  const endSessionURL = `${idpConfig.issuer}/connect/endsession`;
  const endSessionParams = new URLSearchParams({
    id_token_hint: session.idToken ?? "",
    post_logout_redirect_uri: `${redirectUrl}/signout`,
  });

  return `${endSessionURL}?${endSessionParams}`;
}
