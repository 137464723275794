import React from "react";

const CLARITY_MASK = "True";

type DataMaskWrapperProps = {
  children: React.ReactNode;
};

export const DataMaskWrapper = ({ children }: DataMaskWrapperProps) => (
  <div data-clarity-mask={CLARITY_MASK} data-openreplay-obscured>
    {children}
  </div>
);

export const DataMaskWrapperSpan = ({ children }: DataMaskWrapperProps) => (
  <span data-clarity-mask={CLARITY_MASK} data-openreplay-obscured>
    {children}
  </span>
);
