import type { LinkType } from "@components/primary-navigation/types";
import { MAIN_ROUTES, SUB_ROUTES } from "@utils/router-helper";
import { useFeatureFlag } from "@utils/use-feature-flag";
import { useTranslation } from "next-i18next";
import translationMapEvaluation from "src/translation-map/translation-map";

const LIBER_ADMIN_PAGE_URL = "https://online.liber.se/Anvandare/Organisation";

const translations = translationMapEvaluation;

const usePrimaryLinks = (
  hasEvaluationLicense: boolean,
  isLiberUser: boolean,
  refOrganizationId: string | null | undefined,
  isTempClassroomId: boolean,
  gammaWaveEnabled = false,
): LinkType[] => {
  const { t } = useTranslation("common", { keyPrefix: "primary-navigation" });
  const hideNavbarButtonsEnabled = useFeatureFlag("hidePrimaryNavButtons", false);
  const isNewTaskFlowEnabled = useFeatureFlag("useNewTaskPlanningFlow", false);
  const enableFeatureForSpecificUser = useFeatureFlag("enableFeatureForSpesificUser", false);

  const { t: tEvaluation } = useTranslation();
  const primaryNavLinks: LinkType[] = hideNavbarButtonsEnabled
    ? []
    : [
        { link: MAIN_ROUTES.home, title: t("home"), usetifulTag: "menu:home" },
        {
          link: `${MAIN_ROUTES.content}${SUB_ROUTES.contentOverview}`,
          title: t("content"),
          usetifulTag: "menu:content",
          mainRoute: MAIN_ROUTES.content,
        },
      ];

  if (isNewTaskFlowEnabled || enableFeatureForSpecificUser) {
    // We need this on the 2nd position
    primaryNavLinks.splice(1, 0, {
      link: MAIN_ROUTES.tasksManager,
      title: t("tasks-manager"),
      usetifulTag: "menu:tasks-manager",
    });
  }

  if (hasEvaluationLicense && !isTempClassroomId) {
    primaryNavLinks.push(
      {
        link: MAIN_ROUTES.evaluationOverview,
        title: tEvaluation(translations.overview.evaluation),
        usetifulTag: "menu:evaluation-overview",
      },
      {
        link: MAIN_ROUTES.evaluationGrowth,
        title: tEvaluation(translations.overview.growth),
        usetifulTag: "menu:growth",
      },
    );
  }

  if (gammaWaveEnabled) {
    primaryNavLinks.push({
      link: MAIN_ROUTES.gammaWaveProgram,
      title: t("gamma"),
      usetifulTag: "menu:insider",
    });
  }

  const adminPageLink =
    isLiberUser && refOrganizationId
      ? `${LIBER_ADMIN_PAGE_URL}/${refOrganizationId}`
      : MAIN_ROUTES.admin;
  primaryNavLinks.push({
    link: adminPageLink,
    title: t("admin"),
    role: "isAdmin",
    usetifulTag: "menu:admin",
  });

  return primaryNavLinks;
};

export default usePrimaryLinks;
