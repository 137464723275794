import type { LefthandNavigationItem } from "./lefthand-navigation";

import BookOpenReaderIcon from "@infinitaslearning/pixel-icons/BookOpenReader";
import BooksIcon from "@infinitaslearning/pixel-icons/Books";
import ChartSimpleIcon from "@infinitaslearning/pixel-icons/ChartSimple";
import HouseIcon from "@infinitaslearning/pixel-icons/House";
import { useFeatureFlag } from "@utils/use-feature-flag";
import { EvaluationLicenceContext } from "@components/evaluation-licence-context/evaluation-licence-context";
import { useContext, useMemo } from "react";
import { useIsUserWithoutClassroom } from "@utils/use-is-user-without-classroom";
import { useRouter } from "next/router";
import { useGetScoodleItems } from "@components/lefthand-navigation/use-get-scoodle-items";

const getPrimaryTopNavigation = ({
  isNewTaskFlowEnabled,
  hasEvaluation,
  enableFeatureForSpecificUser,
  extraItems = [],
  classroomId,
}: {
  isNewTaskFlowEnabled: boolean;
  hasEvaluation: boolean;
  enableFeatureForSpecificUser: boolean;
  admin?: LefthandNavigationItem;
  extraItems?: LefthandNavigationItem[];
  classroomId: string;
}): LefthandNavigationItem[] =>
  [
    {
      href: "/",
      label: "Home",
      icon: <HouseIcon color="primary" fontSize="large" />,
      isActive: (path: string) => path === "/",
    },
    {
      href: "/content/overview",
      label: "Teaching materials",
      icon: <BooksIcon color="primary" fontSize="large" />,
      isActive: (path: string) => path.startsWith("/content"),
    },
    ...(isNewTaskFlowEnabled || enableFeatureForSpecificUser
      ? [
          {
            href: "/tasks-manager",
            label: "Tasks",
            icon: <ChartSimpleIcon color="primary" fontSize="large" />,
            isActive: (path: string) => path.startsWith("/tasks-manager"),
            routerQuery: { classroomId },
          },
        ]
      : []),
    ...(hasEvaluation
      ? [
          {
            href: "/evaluation-overview",
            label: "Leesradar",
            icon: <BookOpenReaderIcon color="primary" fontSize="large" />,
            isActive: (path: string) => path.startsWith("/evaluation"),
            subItems: [
              {
                href: "/evaluation-overview",
                label: "Overview",
                isActive: (path: string) => path.startsWith("/evaluation-overview"),
              },
              {
                href: "/evaluation-growth",
                label: "Growth",
                isActive: (path: string) => path.startsWith("/evaluation-growth"),
              },
            ],
          },
        ]
      : []),
    ...extraItems,
  ].filter(Boolean) as LefthandNavigationItem[];

const useGetLefthandNavigation = () => {
  const isNewTaskFlowEnabled = useFeatureFlag("useNewTaskPlanningFlow", false);
  const enableNewNavigation = useFeatureFlag("enableNewNavigation", false);
  const enableFeatureForSpecificUser = useFeatureFlag("enableFeatureForSpesificUser", false);
  const { hasEvaluationLicence } = useContext(EvaluationLicenceContext);
  const isTempClassroomId = useIsUserWithoutClassroom();
  const router = useRouter();
  const classroomId = router.query.classroomId as string;
  const { data: scoodleItems } = useGetScoodleItems();

  const navigation = useMemo(() => {
    if (!enableNewNavigation) {
      return null;
    }
    const hasEvaluation = !!(hasEvaluationLicence && !isTempClassroomId);
    return {
      primaryTopNavigation: getPrimaryTopNavigation({
        isNewTaskFlowEnabled,
        hasEvaluation,
        enableFeatureForSpecificUser,
        classroomId,
        extraItems: scoodleItems?.primaryNavigation || [],
      }),
      primaryBottomNavigation: scoodleItems?.bottomNavigation || [],
    };
  }, [
    isNewTaskFlowEnabled,
    hasEvaluationLicence,
    isTempClassroomId,
    enableNewNavigation,
    enableFeatureForSpecificUser,
    classroomId,
    scoodleItems,
  ]);

  return navigation;
};

export default useGetLefthandNavigation;
