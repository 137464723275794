import NavigationMenuItem from "@components/lefthand-navigation/navigation-menu-item";
import { Box } from "@infinitaslearning/pixel-design-system";
import { useEffect, useRef, useMemo } from "react";
import {
  useLeftNavStore,
  PRIMARY_NAV_WIDTH_HOVERED,
  PRIMARY_NAV_WIDTH,
  getBodyPaddingLeft,
} from "@lib/zustand/left-nav-store";
import LefthandSecondaryNavigation from "@components/lefthand-navigation/leftand-secondary-navigation";

const DEFAULT_TIMEOUT_DURATION = 200;

export type LefthandNavigationItem = {
  href: string;
  label: string;
  icon?: React.ReactNode;
  isActive?: (path: string) => boolean;
  subItems?: LefthandNavigationItem[];
  routerQuery?: string;
};

type LefthandNavigationProps = {
  primaryTopNavigation: LefthandNavigationItem[];
  primaryBottomNavigation: LefthandNavigationItem[];
  currentPathWithoutQuery: string;
  timeoutDuration?: number;
};

const LefthandNavigation = ({
  primaryTopNavigation,
  primaryBottomNavigation,
  currentPathWithoutQuery,
  timeoutDuration = DEFAULT_TIMEOUT_DURATION,
}: LefthandNavigationProps) => {
  const { isLeftNavHovered, setIsLeftNavHovered, isSecondaryNavOpen, isDisabledBodyPadding } =
    useLeftNavStore();
  const hoverTimeoutRef = useRef<NodeJS.Timeout>();
  const activeItemWIthSubItems = useMemo(() => {
    return [...primaryTopNavigation, ...primaryBottomNavigation].find(
      (item) =>
        item.isActive?.(currentPathWithoutQuery) &&
        Array.isArray(item.subItems) &&
        item.subItems.length > 0,
    );
  }, [currentPathWithoutQuery, primaryTopNavigation, primaryBottomNavigation]);

  const hasMouseEnabled = useMemo(
    () => typeof window !== "undefined" && window.matchMedia("(pointer:fine)").matches,
    [],
  );

  const handleMouseEnter = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setIsLeftNavHovered(true);
    }, timeoutDuration);
  };

  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    setIsLeftNavHovered(false);
  };

  useEffect(() => {
    document.body.style.paddingLeft = `${getBodyPaddingLeft(
      isLeftNavHovered && !isDisabledBodyPadding,
      !!activeItemWIthSubItems,
      isSecondaryNavOpen,
    )}px`;
  }, [isLeftNavHovered, isSecondaryNavOpen, activeItemWIthSubItems, isDisabledBodyPadding]);

  useEffect(() => {
    document.body.style.transition = "padding-left 0.2s ease-in-out";
    return () => {
      // we do this instead of using css as otherwise we would need to control the padding on multiple components
      document.body.style.paddingLeft = "0";
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <Box
        onMouseEnter={hasMouseEnabled ? handleMouseEnter : undefined}
        onMouseLeave={hasMouseEnabled ? handleMouseLeave : undefined}
        sx={{
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRight: (theme) => `1px solid ${theme.palette.divider}`,
          display: "flex",
          flexDirection: "column",
          zIndex: (theme) => theme.zIndex.appBar,
          transition: "width .2s ease-in-out",
          width: isLeftNavHovered ? PRIMARY_NAV_WIDTH_HOVERED : PRIMARY_NAV_WIDTH,
          overflow: "hidden",
          "& span": {
            opacity: isLeftNavHovered ? 1 : 0,
            transition: "opacity .2s ease-in-out, transform .2s ease-in-out",
            transform: isLeftNavHovered ? "translateX(0)" : "translateX(-20px)",
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
            p: 1,
            justifyContent: "flex-start",
            overflow: "hidden",
          }}
        >
          {primaryTopNavigation.map((item) => (
            <NavigationMenuItem
              key={item.href}
              href={item.href}
              routerQuery={item.routerQuery}
              icon={item.icon}
              label={item.label}
              isActive={item.isActive?.(currentPathWithoutQuery)}
            />
          ))}
        </Box>
        <Box>
          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
              p: 1,
              justifyContent: "flex-start",
              overflow: "hidden",
            }}
          >
            {primaryBottomNavigation.map((item) => (
              <NavigationMenuItem
                key={item.href}
                href={item.href}
                icon={item.icon}
                label={item.label}
                isActive={item.isActive?.(currentPathWithoutQuery)}
              />
            ))}
          </Box>
        </Box>
      </Box>
      {activeItemWIthSubItems && (
        <LefthandSecondaryNavigation
          currentPathWithoutQuery={currentPathWithoutQuery}
          items={activeItemWIthSubItems.subItems ?? []}
        />
      )}
    </>
  );
};

export default LefthandNavigation;
