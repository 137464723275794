import { REACT_QUERY_OPTS_15MINSTALE } from "@lib/react-query";
import {
  formatSubjectAndMethodsDataToMethodData,
  type RecentMethodWeekData,
} from "@pages/home/components/utils";
import { useFeatureFlag } from "@utils/use-feature-flag";
import { useSubjectsAndMethods } from "@utils/use-subjects-and-methods";
import router from "next/router";
import { createContext, useEffect, useMemo, useState } from "react";

const EVALUATION_LICENCE_ID = "f3cb1089-7d29-47d1-9a27-59a74148d087";

type EvaluationLicenceContextType = {
  hasEvaluationLicence: boolean | undefined;
  // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
  evaluationDataError: any;
};

export const EvaluationLicenceContext = createContext<EvaluationLicenceContextType>({
  hasEvaluationLicence: false,
  evaluationDataError: undefined,
});

const EvaluationLicenceProvider = ({
  children,
}: { children: React.ReactNode | React.ReactNode[] }) => {
  const isEvaluationDisabled = useFeatureFlag("isEvaluationDisabled", false);
  const [hasEvaluationLicence, setEvaluationLicence] = useState<boolean | undefined>(undefined);

  const { data: subjectAndMethodsData, error: subjectAndMethodsDataError } = useSubjectsAndMethods(
    undefined,
    {
      ...REACT_QUERY_OPTS_15MINSTALE,
    },
  );

  const classroomId = (router.query.classroomId as string) || "";

  const methodData = useMemo(() => {
    if (!subjectAndMethodsData) return [];
    return formatSubjectAndMethodsDataToMethodData(subjectAndMethodsData, classroomId);
  }, [subjectAndMethodsData, classroomId]);

  useEffect(() => {
    if (methodData.length !== 0 && !!subjectAndMethodsData && !isEvaluationDisabled) {
      setEvaluationLicence(
        methodData.some(
          (method: RecentMethodWeekData) => method.urlComponents.methodId === EVALUATION_LICENCE_ID,
        ),
      );
      return;
    }
    setEvaluationLicence(undefined);
  }, [isEvaluationDisabled, methodData, subjectAndMethodsData]);

  return (
    <EvaluationLicenceContext.Provider
      value={{
        hasEvaluationLicence: hasEvaluationLicence,
        evaluationDataError: subjectAndMethodsDataError,
      }}
    >
      {children}
    </EvaluationLicenceContext.Provider>
  );
};

export default EvaluationLicenceProvider;
