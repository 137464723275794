import * as configcat from "configcat-js-ssr";
import type { IConfigCatClient } from "configcat-common";
import type { OpCo } from "@utils/use-brand";
import type { SafeConfigType } from "src/config";
import { isEmpty } from "lodash";
import { isLocalEnv, isStagingEnv } from "@utils/config";

const configCatClient = new Map<OpCo, IConfigCatClient>();

function initConfigCatClient(opCo: OpCo, config: SafeConfigType) {
  let configCatKey: string | undefined = undefined;
  const {
    overrideOpco,
    configcatKeyDev,
    configcatKeyStaging,
    configcatKeyJuniorCloud,
    configcatKeyLiber,
    configcatKeyPlantyn,
  } = config;

  if (isLocalEnv(config) && isEmpty(overrideOpco)) {
    configCatKey = configcatKeyDev;
  } else if (isStagingEnv(config)) {
    configCatKey = configcatKeyStaging;
  } else {
    switch (opCo) {
      case "liber":
        configCatKey = configcatKeyLiber;
        break;
      case "plantyn":
        configCatKey = configcatKeyPlantyn;
        break;
      // "noordhoff":
      default:
        configCatKey = configcatKeyJuniorCloud;
        break;
    }
  }

  if (!configCatKey) {
    throw `Missing ConfigCatKey for ${opCo}`;
  }

  const client = configcat.getClient(
    configCatKey,
    configcat.PollingMode.LazyLoad,
    process.env.NODE_ENV === "development"
      ? {}
      : {
          cacheTimeToLiveSeconds: 600,
        },
  );

  configCatClient.set(opCo, client);

  return client || null;
}

export function getConfigCatClient(opCo: OpCo, config: SafeConfigType): IConfigCatClient | null {
  const opCoClient = configCatClient.get(opCo);

  if (!opCoClient) {
    return initConfigCatClient(opCo, config);
  }

  return opCoClient;
}
