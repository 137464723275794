import {
  type GetSubjectsAndMethodsQuery,
  type GetSubjectsAndMethodsQueryVariables,
  useGetSubjectsAndMethodsQuery,
} from "@generated/graphql";
import type { UseQueryOptions } from "@tanstack/react-query";
import { usePlatformPreferences } from "@utils/initializers/preferences";
import { useMemo } from "react";

export const useSubjectsAndMethods = (
  variables?: GetSubjectsAndMethodsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetSubjectsAndMethodsQuery, unknown, GetSubjectsAndMethodsQuery>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      GetSubjectsAndMethodsQuery,
      unknown,
      GetSubjectsAndMethodsQuery
    >["queryKey"];
  },
) => {
  const queryResult = useGetSubjectsAndMethodsQuery(variables, options);
  const { value: filteredMethodLicenses } = usePlatformPreferences("filteredMethodLicenses");

  const result = useMemo(() => {
    const { data } = queryResult;

    if (!data) return queryResult;
    if (!filteredMethodLicenses || filteredMethodLicenses.length <= 0) return queryResult;

    const filtered = {
      ...data,
      subjects: data.subjects.map((s) => ({
        ...s,
        methodsLicensed: s.methodsLicensed.filter((m) => filteredMethodLicenses.includes(m.id)),
      })),
    };

    return { ...queryResult, data: filtered };
  }, [queryResult, filteredMethodLicenses]);

  return result;
};
