import { type GetSubjectsAndMethodsQuery, useGetSubjectsAndMethodsQuery } from "@generated/graphql";
import {
  Autocomplete,
  Box,
  Button,
  Icon,
  InputLabel,
  TextField,
  Tooltip,
} from "@infinitaslearning/pixel-design-system";
import { usePlatformPreferences } from "@utils/initializers/preferences";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "@infinitaslearning/pixel-icons/CircleInfo";

const FilterDisplay = ({
  data,
  filteredMethods,
  setfilteredMethodLicenses,
}: {
  data: GetSubjectsAndMethodsQuery;
  filteredMethods: string[];
  setfilteredMethodLicenses: (newValue: string[]) => Promise<void>;
}) => {
  const { t } = useTranslation("common");

  const methods = useMemo(
    () =>
      data.subjects
        .flatMap((x) => x.methodsLicensed.map((m) => ({ label: m.name, id: m.id })))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    [data],
  );

  const [selectedMethods, setSelectedMethods] = useState<{ label: string; id: string }[]>(() =>
    methods.filter((m) => filteredMethods.includes(m.id)),
  );

  useEffect(() => {
    setfilteredMethodLicenses(selectedMethods.map((m) => m.id));
  }, [selectedMethods, setfilteredMethodLicenses]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", my: 2, gap: 1 }}>
      <Tooltip title={t("user-sidebar.filtered-methods-tooltip")}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <InputLabel>{t("user-sidebar.filtered-methods-label")}</InputLabel>
          <Icon component={InfoIcon} fontSize="medium" color="neutral" />
        </Box>
      </Tooltip>
      <Autocomplete
        size="small"
        multiple
        options={methods}
        value={selectedMethods}
        onChange={(_, v) => setSelectedMethods(v)}
        renderInput={(params) => <TextField {...params} placeholder={t("user-sidebar.select")} />}
        dense={true}
      />
      <Button
        onClick={() => window.location.reload()}
        size="small"
        variant="contained"
        fullWidth={true}
      >
        {t("save")}
      </Button>
    </Box>
  );
};

export const MethodLicenseFilter = () => {
  const { data, isSuccess } = useGetSubjectsAndMethodsQuery();
  const {
    value: filteredMethodLicenses,
    isLoading: filteredMethodLicensesLoading,
    setValue: setfilteredMethodLicenses,
  } = usePlatformPreferences("filteredMethodLicenses");

  if (isSuccess && !filteredMethodLicensesLoading)
    return (
      <FilterDisplay
        data={data}
        filteredMethods={filteredMethodLicenses ?? []}
        setfilteredMethodLicenses={setfilteredMethodLicenses}
      />
    );

  return <></>;
};
